.PageLogin.container-full {
  width: 100vw;
  height: 100vh;
  /* overflow: scroll; */
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.PageLogin .side-logo {
  display: grid;
  place-items: center;
}

.login-form{
  margin-top: -100px;
}
.input-size {
  width:340px;
}

.PageLogin .side-login {
  display: grid;
  grid-template-rows: 80px 1fr;
}
.PageLogin .header-login {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.PageLogin .icon-logo {
  align-self: center;
  grid-column: 2;
  justify-self: end;
  margin-right: 1rem;
}
.PageLogin .logo-login {
  display: none;
}

.center {
  margin: auto;
  width: 50%;
}

.PageLogin .form {
  place-self: center;
  padding: 20px;
  width: 60%;
  min-width: 320px;
  max-width: 500px;
}
.PageLogin .button {
  width: 50%;
  height: 45px;
  min-width: 100px;
  max-width: 200px;
}


@media only screen and (max-width: 1200px) {
  
  .input-size{
    width:230px
  }
 
}

/* Responsive */
@media only screen and (max-width: 750px) {
  .login-form{
    margin-top: 0;
  }
  .PageLogin.container-full {
    grid-template-columns: 1fr;
  }
  .PageLogin .side-logo {
    display: none;
  }
  .PageLogin .logo-login {
    justify-self: start;
    display: block;
    width: 120px;
  }
  .PageLogin .header-login {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .PageLogin .form {
    justify-self: center;
    margin-top: 2rem;
    align-self: start;
    padding: 20px;
    width: 60%;
    min-width: 320px;
    max-width: 500px;
  }
}
